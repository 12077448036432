import React from "react";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import CheckBox from "../CheckBox";
import { agesValues } from "../../constants/lists";

const AgesSelection = ({
  setData,
  data,
  isInvalid,
  title,
}) => {
  function handleAgeServed(ev) {
    const ages = data[ev.target.name] || [];
    if (ages.includes(ev.target.value)) {
      setData({
        [ev.target.name]: ages.filter((item) => item !== ev.target.value),
      });
    } else {
      setData({
        [ev.target.name]: [...ages, ev.target.value],
      });
    }
  }

  function handleAllAges(ev) {
    if (ev.target.checked) {
      setData({
        age_served: agesValues,
      });
    } else {
      setData({
        age_served: [],
      });
    }
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <Typography variant="h5">{title || "Ages"}</Typography>

      <Box className="flexHours">
        {agesValues.map((ageValue, index) => (
          <Button
            fullWidth
            key={index}
            variant="outlined"
            size="large"
            color="secondary"
            active={data.age_served?.includes(ageValue).toString()}
            sx={{
              borderRadius: 15,
              fontWeight: 600,
              color: "text.primary",
              whiteSpace: "nowrap",
            }}
            value={ageValue}
            onClick={handleAgeServed}
            name="age_served"
          >
            {ageValue}
          </Button>
        ))}
      </Box>

      <CheckBox
        label="All ages"
        checked={data.age_served?.length === 9}
        onChange={handleAllAges}
      />

      {isInvalid && (data.age_served || []).length === 0 && (
        <Typography variant="caption" color="error">
          Please select at least one age group.
        </Typography>
      )}
    </Box>
  );
};

export default AgesSelection;
