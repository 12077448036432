import React, { createContext, useContext, useEffect } from "react";
import Cookies from "universal-cookie";
import {
  changePassword,
  forgetPassword,
  login,
  resendEmail,
  resetPassword,
} from "../services/auth.services";
import { useState } from "react";
import { ERROR, LOADING, SUCCESS, domain, treatment_program } from "../constants/common";
import { useAlert } from "./Alert";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import paths from "../constants/paths";
import { identifyUser } from '../mixpanel';

const AuthContext = createContext({});
const cookies = new Cookies();
let intervalId;
export const AuthContextProvider = ({ children }) => {
  const { sendAlert } = useAlert();
  const initialUserData = {
    first_name: "",
    last_name: "",
  };

  const [authState, setAuthState] = useState({
    loginStatus: "",
    userData: initialUserData,
    forgetPasswordStatus: "",
    resetPasswordStatus: "",
    isLoggedIn: JSON.parse(localStorage.getItem("isLoggedIn") || "false"),
    account_type: localStorage.getItem("account_type") || "",
    resendEmailStatus: "",
    isLogginBlocked: true,
    loginBlockDate: localStorage.getItem("loginBlockDate") || "",
    countDown: '30:00',
    updatePasswordStatus: ""
  });
  const navigate = useNavigate();

  const [step, setStep] = useState(1);

  function next() {
    setStep((prevState) => prevState + 1);
  }

  function prev() {
    setStep((prevState) => (prevState > 1 ? prevState - 1 : prevState));
  }

  function handleState(obj) {
    setAuthState((prevState) => ({
      ...prevState,
      ...obj,
    }));
  }

  async function signIn(data) {
    handleState({ loginStatus: LOADING });
    try {
      const response = await login(data);
      
      const duration = moment.duration(moment().diff(moment(response?.data?.data?.user?.password_changed_at)));
      const days = duration.asDays();
      if (days >= 90 && response?.data?.data?.user?.account_type === treatment_program) {
        localStorage.setItem("accessToken", response.data.data.access_token);
        navigate(paths.changePassword)
      } else {
        if (response.data?.error) {
          sendAlert(response.data?.error, "error");
          if(!localStorage.getItem("loginBlockDate")) {
            localStorage.setItem("loginBlockDate", moment());
            handleState({
              isLogginBlocked: true,
              loginBlockDate: moment(),
            });
          }
        } else if (response?.data?.data?.access_token) {
          localStorage.setItem("accessToken", response.data.data.access_token);
          localStorage.setItem("userId", response?.data?.data?.user?.id);
          localStorage.setItem(
            "account_type",
            response.data.data?.user?.account_type
          );
          localStorage.setItem("isLoggedIn", "true");
          identifyUser(response.data.data?.user);
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 1);
    
          cookies.set("isLoggedIn", "true", {
            expires: expirationDate,
            domain: `.${domain}`,
            secure: true,
          });
          handleState({
            loginStatus: SUCCESS,
            userData: response?.data?.data?.user,
            isLoggedIn: true,
            account_type: response?.data?.data?.user?.account_type,
            isLogginBlocked: false,
          });
        }
        if ((days < 90 && days >= 85) && response?.data?.data?.user?.account_type === treatment_program) {
          sendAlert(`Password Expiration Notice: Your password will expire ${90 - Math.round(days)} days`, "error");
        } else {
          sendAlert("Logged in successfully", "success");
        }
      }
      
    } catch (error) {
      handleState({ loginStatus: ERROR });
      if (error.response?.data?.error == "") {
        sendAlert(null, "error", true);
      } else {
        sendAlert(error.response?.data?.error || error.response?.data?.message, "error");
      }
      Promise.reject(error);
    }
  }

  async function forgotPassword(data) {
    handleState({ forgetPasswordStatus: LOADING });
    try {
      const response = await forgetPassword(data);
      if (response.status === 200) {
        setStep(2);
        handleState({
          forgetPasswordStatus: SUCCESS,
        });
      }
      return response;
    } catch (error) {
      handleState({ forgetPasswordStatus: ERROR });
      sendAlert(error.response?.data?.message, "error");
      Promise.reject(error);
    }
  }

  async function passwordReset(data) {
    handleState({ resetPasswordStatus: LOADING });
    try {
      const response = await resetPassword(data);
      if (response.status === 200) {
        sendAlert("Password has been reset successfully!", "success");
        handleState({
          resetPasswordStatus: SUCCESS,
        });
        if (step === 2) {
          sendAlert("Your request has been sent successfully", "success");
        }
      }
      return response;
    } catch (error) {
      handleState({ resetPasswordStatus: ERROR });
      sendAlert(error.response?.data?.message, "error");
      Promise.reject(error);
    }
  }

  async function resendEmailVerification(data) {
    handleState({ resendEmailStatus: LOADING });
    try {
      const response = await resendEmail(data);
      sendAlert("Email has been sent", "success");
      handleState({ resendEmailStatus: SUCCESS });
      return response;
    } catch (error) {
      handleState({ resendEmailStatus: ERROR });
      sendAlert(error.response?.data?.message, "error");
      Promise.reject(error);
    }
  }

  async function updatePassword(data) {
    handleState({ updatePasswordStatus: LOADING });
    try {
      const response = await changePassword(data);
      sendAlert("Password has been changed successfully!", "success");
      handleState({
        updatePasswordStatus: SUCCESS,
      });
      return response;
    } catch (error) {
      handleState({ updatePasswordStatus: ERROR });
      const errorsValues = error.response?.data?.errors && Object.keys(error.response?.data?.errors || {}).map((key) => {
        if (typeof error.response?.data?.errors[key] === 'object') {
            if (Array.isArray(error.response?.data?.errors[key])) {
                return error.response?.data?.errors[key]
            }
            return '';
        }
        return error.response?.data?.errors[key]
    })
      sendAlert(errorsValues || error.response?.data?.message, "error");
      Promise.reject(error);
    }
  }
  

  function logout() {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userData");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("step");
    localStorage.removeItem("account_type");
    localStorage.removeItem("onboardingData");
    cookies.remove("isLoggedIn", { domain: `.${domain}` });
    handleState({
      loginStatus: "",
      userData: initialUserData,
      isLoggedIn: false,
    });
  }

  function updateAuthState(field, value) {
    handleState({ [field]: value });
  }

  useEffect(() => {
    if (localStorage.getItem('loginBlockDate')) {
      intervalId = setInterval(() => { 
        const fiveMinutesAfter = moment(authState.loginBlockDate).add(30, 'minutes');
        const isBlocked = moment().isBefore(fiveMinutesAfter);
        const duration = moment.duration(fiveMinutesAfter.diff(moment()));
        const sec_num = Math.round(duration.asSeconds());
        let minutes = Math.floor((sec_num) / 60);
        let seconds = sec_num - (minutes * 60);

        if (minutes < 10) {minutes = "0"+minutes;}
        if (seconds < 10) {seconds = "0"+seconds;}
        handleState({
          isLogginBlocked: isBlocked,
          countDown: minutes+':'+seconds
        });
        if (!isBlocked) {
          handleState({
            isLogginBlocked: false
          });
          localStorage.removeItem('loginBlockDate')
          clearInterval(intervalId);
        }
      }, 1000)
    } else {
      handleState({
        isLogginBlocked: false
      });
      clearInterval(intervalId);
    }
  }, [authState.loginBlockDate])
  
  const state = {
    ...authState,
    signIn,
    forgotPassword,
    step,
    next,
    prev,
    passwordReset,
    logout,
    resendEmailVerification,
    updatePassword,
    updateAuthState,
  };

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
