import React, { useState } from 'react'
import { Paper, Typography, Box, Button, TextField, Grid, MenuItem, Container, Avatar, FormControl, InputLabel, Select, Checkbox } from '@mui/material';
import { useOnboarding } from '../../../contexts/onboarding';
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg'
import { ReactComponent as UploadIcon } from '../../../assets/Icons/upload.svg'
import { useCommon } from '../../../contexts/common';
import { useEffect } from 'react';
import { facility_types } from '../../../constants/common';
import PlaceAutoComplete from '../../PlaceAutoComplete';
import PhoneNumberInput from '../../PhoneNumberInput';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { treatmentProgramProfileValidationSchema } from '../../../validations/registerValidation';
import { formatUrl } from '../../../utils/helper';
import { useAlert } from '../../../contexts/Alert';
import { imageTypes } from '../../../constants/lists';
import CustomButton from '../../CustomButton';

const TreatmentProgramProfile = () => {
  const { next, onboardingState, updateOnboardingState } = useOnboarding();
  const { statesList, fetchStatesList, fetchTreatmentTypes, facilityTypesList } = useCommon();
  const [countryState, setCountryState] = useState(null);
  const [profileImageURL, setProfileImageURL] = useState(null);
  const [imageError, setImageError] = useState(null);
  const { sendAlert } = useAlert();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(treatmentProgramProfileValidationSchema),
    mode: "onChange",
    defaultValues: {
      facility_name: onboardingState.facility_name || '',
      facility_type_ids: onboardingState.facility_type_ids || [],
      phone_number: onboardingState.phone_number || '',
      website_link: onboardingState.website_link || '',
      address1: onboardingState.address1 || '',
      city: onboardingState.city || '',
      state: onboardingState.state || 0,
      zip_code: onboardingState.zip_code || '',
      facility_image: onboardingState.facility_image || null,
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    updateOnboardingState(formValues);
  }, [formValues]);

  useEffect(() => {
    setImageError(errors.facility_image?.message)
  }, [errors.facility_image]?.message)

  useEffect(() => {
    fetchStatesList();
    fetchTreatmentTypes(facility_types);
  }, [])

  useEffect(() => {
    const updateState = async () => {
      if (countryState) {
        const stateId = statesList.find((st) => st.name === countryState)?.id;
        setValue("state", stateId);
        await trigger('state');
      }
    }
    updateState();
  }, [countryState]);

  useEffect(() => {
    if (onboardingState?.facility_image) {
      handleFileRead(onboardingState.facility_image);
    }
  }, [onboardingState.facility_image]);

  const handleFileRead = (file) => {
    if (!file || !file.type?.startsWith('image/')) {
      setProfileImageURL(null);
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      setProfileImageURL(event.target.result);
    };
    reader.onerror = () => {
      console.error('Error reading the file.');
      sendAlert("There is an issue while reading this file", "error")
    };
    reader.readAsDataURL(file);
  };


  const handlePhoneNumberChange = (formattedNumber) => {
    setValue("phone_number", formattedNumber.phone_number);
  };

  const handleFileInput = async (event) => {
    const file = event.target.files?.[0];

    if (!file) {
      setProfileImageURL(null);
      setValue('facility_image', null);
      return;
    }

    if (file.size > 5 * 1024 * 1024) { // 5 MB
      errors['facility_image'] = { message: 'The logo must be less than 5 MB.' };
      setProfileImageURL(null);
      setValue('facility_image', null);
      return;
    }

    if (!imageTypes.includes(file.type)) { // 5 MB
      // sendAlert('The logo must be less than 5 MB.', 'error');
      errors['facility_image'] = { message: 'Please upload a valid image file.\n(jpeg, jpg, png, svg+xml)' };
      setProfileImageURL(null);
      setValue('facility_image', null);
      return;
    }

    if (!file.type.startsWith('image/')) {
      // sendAlert('Please select a valid image file.', 'error');
      setProfileImageURL(null);
      setValue('facility_image', null);
      errors['facility_image'] = { message: 'Please select a valid image file.' };
      return;
    }
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = () => {
      const width = img.width;
      const height = img.height;

      if (width < 300 || height < 300) {
        errors['facility_image'] = { message: 'The image dimensions should be at least 300x300 pixels.' };
        setProfileImageURL({});
        setValue('facility_image', {});
        return;
      }

      if (width > 2000 || height > 2000) {
        errors['facility_image'] = { message: 'The image dimensions should not exceed 2000x2000 pixels.' };
        setProfileImageURL({});
        setValue('facility_image', {});
        return;
      }
    }
    img.onerror = () => {
      errors['facility_image'] = { message: 'There was an error loading the image. Please select a valid image file.' };
      setProfileImageURL({});
      setValue('facility_image', {});
      return;
    };


    setValue('facility_image', file);
    errors['facility_image'] = {};
    setImageError('');
    updateOnboardingState({ facility_image: file });
    handleFileRead(file);
  };


  const handleAddressChange = async (address) => {
    const streetAddress = address?.location?.split(",")[0] || '';
    setCountryState(address?.state);

    setValue("address1", streetAddress);
    setValue("city", address.city);
    setValue("zip_code", address.zipCode);
    await trigger();
  };

  const onSubmit = () => {
    if (isValid)
      next()
  }


  return (
    <Container maxWidth='md'>
      <Paper
        sx={{
          p: 6,
          boxShadow: '0px 0px 64px rgba(0, 0, 0, 0.04)',
          border: 0
        }}
      >
        <Typography variant='h5' mb={1.5}>
          Treatment Program Profile
        </Typography>

        <Grid container spacing={1.5} pb={3}>
          <Grid item xs={12}>
            <Controller
              name="facility_name"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant='outlined'
                  label='Treatment Facility Name'
                  name="facility_name"
                  fullWidth
                  error={!!errors.facility_name}
                  helperText={errors.facility_name?.message}
                />
              )}
            />

          </Grid>
          <Grid item xs={12}>
            <Controller
              name="facility_type_ids"
              control={control}
              render={({ field }) => (
                <FormControl fullWidth error={!!errors.facility_type_ids}>
                  <InputLabel id="facility-type-label">Treatment Facility Type</InputLabel>
                  <Select
                    {...field}
                    labelId="facility-type-label"
                    label="Treatment Facility Type"
                    fullWidth
                    renderValue={(selected) =>
                      selected
                        .map((val) =>
                          facilityTypesList.find((item) => item.id === +val)?.name
                        )
                        .join(', ')
                    }
                    multiple
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224,
                          width: 250,
                        },
                      },
                    }}
                    IconComponent={(props) => (
                      <Box
                        {...props}
                        component="span"
                        sx={{
                          minWidth: 24,
                          width: 24,
                          height: 24,
                          display: 'inline-flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          top: '50% !important',
                          right: '16px !important',
                          transform: 'translateY(-50%) !important',
                        }}
                      >
                        <ChevronDown width={12} height={6} />
                      </Box>
                    )}
                    onChange={(event) => {
                      const value = event.target.value;
                      field.onChange(value);
                    }}
                    value={field.value || []}
                  >
                    <MenuItem
                      sx={{ p: 0 }}
                      value={null}
                      disabled
                    />
                    {facilityTypesList.map((item) => (
                      <MenuItem
                        sx={{ py: 1, gap: 1 }}
                        value={item.id}
                        key={item.id}
                      >
                        <Checkbox sx={{ p: 0 }} checked={Boolean(field.value?.find((val) => +val === item.id))} />
                        <Typography variant='body2'>{item.name}</Typography>
                      </MenuItem>
                    ))}
                  </Select>
                  {errors.facility_type_ids && <Typography variant="caption" color="error">{errors.facility_type_ids.message}</Typography>}
                </FormControl>
              )}
            />
          </Grid>

          <Grid item xs={8}>
            <PhoneNumberInput
              fullWidth
              variant="outlined"
              sx={{ mb: 1.5 }}
              value={onboardingState.phone_number || ""}
              onChange={handlePhoneNumberChange}
              placeholder="(123) 456-7890"
              error={!!errors.phone_number}
              helperText={errors.phone_number?.message}
              name='phone_number'
              label='Phone number'
              control={control}
              trigger={trigger}
            />
            <Controller
              name="website_link"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="Website Link"
                  fullWidth
                  error={!!errors.website_link}
                  helperText={errors.website_link?.message}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value === "" || value === `https://`) {
                      field.onChange("");
                      return;
                    }
                    const formattedValue = formatUrl(value);
                    field.onChange(formattedValue);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Box
              sx={{
                backgroundColor: 'background.default',
                border: '1px solid',
                borderColor: 'secondary.main',
                borderRadius: 1.5,
                height: 142,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 1,
                color: 'border.dark',
                cursor: 'pointer',
                overflow: 'hidden',
                position: 'relative'
              }}
              component='label'
            >
              {profileImageURL ? (
                <>
                  <Avatar
                    src={profileImageURL}
                    sx={{ width: '100%', height: 'auto', borderRadius: 0 }}
                  />
                  <Typography
                    variant='body2'
                    sx={{
                      textAlign: 'center',
                      position: 'absolute',
                      bottom: 0,
                      backgroundColor: 'rgba(0,0,0,0.4)',
                      width: '100%',
                      color: 'background.paper'
                    }}
                  >
                    Edit
                  </Typography>
                </>
              ) : (
                <>
                  <UploadIcon />
                  <Typography variant='body2' color='text.primary'>Upload Logo</Typography>
                </>
              )}
              <input
                type='file'
                accept='image/*'
                onChange={handleFileInput}
                style={{ display: 'none' }}
                name='facility_image'
                error={!!errors.facility_image}
                helperText={errors.facility_image?.message} />
              {!!errors.facility_image &&
                <Typography variant="caption" color="error" sx={{ textAlign: 'center' }}>
                  {errors.facility_image?.message}
                </Typography>
              }
            </Box>
          </Grid>
        </Grid>

        <Typography variant='h5' mb={1.5}>
          Primary program address
        </Typography>

        <Grid container spacing={1.5}>
          <Grid item xs={12}>
            <PlaceAutoComplete
              name="address1"
              variant="outlined"
              label="Street address"
              fullWidth
              onChange={handleAddressChange}
              error={!!errors.address1}
              helperText={errors.address1?.message}
              control={control}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="city"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="City"
                  fullWidth
                  error={!!errors.city}
                  helperText={errors.city?.message}
                  onChange={(e) => {
                    const { value } = e.target;
                    field.onChange(value);
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="state"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  select
                  variant="outlined"
                  label="State"
                  fullWidth
                  error={!!errors.state}
                  helperText={errors.state?.message}
                  SelectProps={{
                    IconComponent: () => (
                      <Box
                        sx={{
                          minWidth: 24,
                          width: 24,
                          height: 24,
                          display: "inline-flex",
                          alignItems: "center",
                          justifyContent: "center",
                          mr: 2,
                        }}
                      >
                        <ChevronDown width={12} height={6} />
                      </Box>
                    ),
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 220,
                          width: 250,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={0} disabled>
                    Select a state
                  </MenuItem>
                  {statesList.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name="zip_code"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  variant="outlined"
                  label="ZIP code"
                  fullWidth
                  error={!!errors.zip_code}
                  helperText={errors.zip_code?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>

      <Box pt={5} textAlign='center'>
        <CustomButton
          variant='contained'
          sx={{
            minWidth: 212
          }}
          onClick={(e) => handleSubmit(onSubmit)(e)}
          name='Continue'
          page='Register'
          eventName='TP-Step 3 - TP profile'
        >
          Continue
        </CustomButton>
      </Box>
    </Container>
  )
}

export default TreatmentProgramProfile;