import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { LOADING, SUCCESS } from "../../constants/common";
import { ReactComponent as ChevronDown } from "../../assets/Icons/chevron-down.svg";
import { useCommon } from "../../contexts/common";
import { useDegrees } from "../../contexts/Degrees";
import { useBasicInfo } from "../../contexts/BasicInfo";
import { useOnboarding } from "../../contexts/onboarding";
import { Controller, useForm, useWatch } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { educationSchema } from "../../validations/validations";

const DegreeDrawer = ({ onClose, open, data, isLocalEditing }) => {
  const { degreesList, fetchDegreesList } = useCommon();
  const {
    fetchUserDegrees,
    createUserDegree,
    editUserDegree,
    updateDegreeState,
    updateStatus,
    createStatus,
    openConfirmationModal,
    deleteStatus,
  } = useDegrees();
  const { fetchInfo, data: info } = useBasicInfo();
  const { setOnboardingState } = useOnboarding();

  const initialState = {
    professional_degree_id: null,
    degree_year: "",
    college_name: "",
    practice_year: "",
  };

  const isEdit = Boolean(data);
  const [state, setState] = useState(initialState);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    trigger,
    reset,
    setValue,
  } = useForm({
    resolver: zodResolver(educationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const formValues = useWatch({ control });


  useEffect(() => {
    if (open) fetchDegreesList();
  }, [open]);

  useEffect(() => {
    setState({ ...formValues });
  }, [formValues]);

  useEffect(() => {
    if (deleteStatus === SUCCESS) {
      handleClose();
    }
  }, [deleteStatus]);

  useEffect(() => {
    if (isEdit && open) {
      setValue('professional_degree_id', parseInt(data.professional_degree_id || null));
      setValue('degree_year', data.degree_year);
      setValue('college_name', data.college_name || '');
      setValue('practice_year', data.practice_year);
    }
  }, [data, open, isEdit]);

  useEffect(() => {
    if (updateStatus === SUCCESS) {
      updateDegreeState("updateStatus", "");
      fetchUserDegrees();
      handleClose();
    }
  }, [updateStatus]);

  useEffect(() => {
    if (createStatus === SUCCESS) {
      updateDegreeState("createStatus", "");
      fetchUserDegrees();
      if (info?.degrees?.length < 1) fetchInfo();
      handleClose();
    }
  }, [createStatus]);


  const handleClose = () => {
    setState(initialState);
    reset();
    onClose();
  }

  const handleInput = (ev) => {
    setValue(`${ev.target.name}`, ev.target.value);
    trigger(ev.target.name)
  }

  const onSubmit = () => {
    if (isValid) {
      if (isEdit) {
        if (isLocalEditing) {
          setOnboardingState((prevState) => ({
            ...prevState,
            degrees: (prevState.degrees || []).map((item, index) => {
              if (data.index === index) {
                return state;
              }
              return item;
            }),
          }));
          handleClose();
        } else {
          editUserDegree({ ...state, id: data.id });
        }
      } else {
        if (isLocalEditing) {
          setOnboardingState((prevState) => ({
            ...prevState,
            degrees: [...(prevState.degrees || []), { ...state }],
          }));
          handleClose();
        } else {
          setOnboardingState((prevState) => ({
            ...prevState,
            degrees: [...(prevState.degrees || []), { ...state }],
          }));
          createUserDegree(state);
        }
      }
    }
  }

  return (
    <Drawer open={open} onClose={handleClose} anchor="right">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          pt: 0,
        }}
      >
        <Box pb={3} px={3}>
          <Typography variant="h4" mb={1}>
            Education
          </Typography>
          <Typography variant="body1" mb={1}>
            Where did you go to school, and when did you begin practicing?
          </Typography>
          <Divider sx={{ mb: 4 }} />
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="professional_degree_id"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    variant="outlined"
                    label="Professional Degree"
                    fullWidth
                    SelectProps={{
                      IconComponent: () => (
                        <Box
                          sx={{
                            minWidth: 24,
                            width: 24,
                            height: 24,
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            mr: 2,
                          }}
                        >
                          <ChevronDown width={12} height={6} />
                        </Box>
                      ),
                      MenuProps: {
                        PaperProps: {
                          style: {
                            maxHeight: 220,
                            width: 250,
                          },
                        },
                      },
                    }}
                    onChange={handleInput}
                    value={state.professional_degree_id || 0}
                    error={!!errors.professional_degree_id}
                    helperText={
                      errors?.professional_degree_id?.message
                    }
                  >
                    <MenuItem value={0} disabled>
                      Select a degree
                    </MenuItem>
                    {degreesList?.map((degree) => (
                      <MenuItem key={degree.id} value={degree.id}>
                        {degree.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="degree_year"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{boxSizing: 'content-box'}}
                    variant="outlined"
                    label="Year graduated (YYYY)"
                    onChange={handleInput}
                    value={state.degree_year || ""}
                    fullWidth
                    error={!!errors?.degree_year}
                    helperText={errors?.degree_year?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={'practice_year'}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{boxSizing: 'content-box'}}
                    variant="outlined"
                    label="Year Began Practicing (YYYY)"
                    onChange={handleInput}
                    fullWidth
                    value={state.practice_year || ""}
                    error={!!errors?.practice_year}
                    helperText={errors?.practice_year?.message}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="college_name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    sx={{boxSizing: 'content-box'}}
                    variant="outlined"
                    label="School Name"
                    onChange={handleInput}
                    fullWidth
                    value={state.college_name || ""}
                    error={!!errors?.college_name}
                    helperText={errors?.college_name?.message}
                  />
                )}
              />
            </Grid>
            {isEdit && (
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button
                  onClick={openConfirmationModal}
                  size="small"
                  color="error"
                  sx={{
                    minWidth: 120,
                    backgroundColor: "error.light",
                    color: "error.main",
                  }}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        <Box
          sx={{
            boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.08)",
            px: 1,
            pt: 3,
            pb: 5.625,
            display: "flex",
            alignItems: "center",
            gap: 1,
            position: "sticky",
            width: "100%",
            backgroundColor: "background.paper",
            bottom: 0,
            zIndex: 9,
          }}
        >
          <Button
            variant="outlined"
            color="secondary"
            sx={{
              color: "primary.main",
            }}
            size="large"
            fullWidth
            onClick={handleClose}
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            size="large"
            fullWidth
            loading={[createStatus, updateStatus].includes(LOADING)}
            onClick={handleSubmit(onSubmit)}
          >
            {isEdit ? "Save" : "Create"}
          </LoadingButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DegreeDrawer;
