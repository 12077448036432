import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Grid from '@mui/material/Grid';
import { ReactComponent as EditIcon } from '../../../assets/Icons/edit-3.svg'
import { ReactComponent as CloseIcon } from '../../../assets/Icons/close.svg'
import { ReactComponent as ChevronDown } from '../../../assets/Icons/chevron-down.svg'
import { useGeneralProfile } from '../../../contexts/Organization';
import { LOADING, SUCCESS } from '../../../constants/common';
import { MenuItem } from '@mui/material';
import { useCommon } from '../../../contexts/common';
import useGeolocation from '../../../hooks/use-GeoLocation';
import { validatePhoneNumber, validateStreetAddress, validateText, validateZipcode } from '../../../helper/Validations';
import PhoneNumberInput from '../../../components/PhoneNumberInput';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { treatmentProgramAddressSchema } from '../../../validations/profileValidations';
import PlaceAutoComplete from '../../../components/PlaceAutoComplete';

const Contact = () => {
  const {
    updateFacilityContactStatus,
    editFacilityContact,
    updateGeneralProfileState,
    facilityInfo,
    fetchFacilityInfo
  } = useGeneralProfile();
  const { statesList, fetchStatesList } = useCommon();
  const initalState = {
    phone_number: '',
    fax_number: '',
    address1: '',
    city: '',
    state: null,
    zip_code: '',
  }
  const [isEditMode, setIsEditMode] = useState(false);
  const [state, setState] = useState(initalState);
  const [isInvalid, setIsInvalid] = useState(false);
  const [countryState, setCountryState] = useState(null);
  const { loading, location } = useGeolocation(state.zip_code);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    trigger,
  } = useForm({
    resolver: zodResolver(treatmentProgramAddressSchema),
    mode: 'onChange',
    defaultValues: {
      phone_number: '',
      fax_number: '',
      address1: '',
      city: '',
      state: 0,
      zip_code: '',
    },
  });

  const formValues = useWatch({ control });

  useEffect(() => {
    setState({ ...formValues });
  }, [formValues]);

  useEffect(() => {
    const updateState = async () => {
      if (countryState) {
        const stateId = statesList.find((st) => st.name === countryState)?.id;
        setValue('state', stateId);
        await trigger();
      }
    }
    updateState();
  }, [countryState]);


  const handleInput = (ev) => {
    setValue(`${ev.target.name}`, ev.target.value);
  }

  const handlePhoneNumberInput = (fomattedNumber) => setValue('phone_number', fomattedNumber.phone_number);
  const handleFaxNumberInput = (fomattedNumber) => setValue('fax_number', fomattedNumber.fax_number);


  const handleOpenEditMode = () => {
    setIsEditMode(true)
  }

  const handleCloseEditMode = () => {
    setIsEditMode(false)
    setIsInvalid(false);
    setValue('phone_number', facilityInfo?.info?.phone_number || '');
    setValue('fax_number', facilityInfo?.info?.fax_number || '');
    setValue('address1', facilityInfo?.info?.address?.address1 || '');
    setValue('city', facilityInfo?.info?.address?.city || '');
    setValue('state', facilityInfo?.info?.address?.state?.id || '');
    setValue('zip_code', facilityInfo?.info?.address?.zip_code || '');
  }

  const handleAddressChange = async (address) => {
    const streetAddress = address?.location?.split(',')[0] || '';
    setCountryState(address?.state);
    setValue('address1', streetAddress || '');
    setValue('city', address.city || '');
    setValue('zip_code', address.zipCode || '');
    await trigger();
  };

  const onSubmit = () => {
    if (isValid) {
      editFacilityContact(state);
    }
  }

  useEffect(() => {
    if (updateFacilityContactStatus === SUCCESS) {
      updateGeneralProfileState('updateFacilityContactStatus', '')
      setIsEditMode(false)
      setIsInvalid(false);
      fetchFacilityInfo();
    }
  }, [updateFacilityContactStatus])

  useEffect(() => {
    if (facilityInfo) {
      setValue('address1', facilityInfo?.info?.address?.address1 || '');
      setValue('state', facilityInfo?.info?.address?.state?.id || 0);
      setValue('city', facilityInfo?.info?.address?.city || '');
      setValue('zip_code', facilityInfo?.info?.address?.zip_code || '');
      setValue('phone_number', facilityInfo?.info?.phone_number || '');
      setValue('fax_number', facilityInfo?.info?.fax_number || '');
    }
  }, [facilityInfo])

  useEffect(() => {
    fetchStatesList();
  }, [])

  useEffect(() => {
    if ((location.latitude && location.longitude) && state.zip_code) {
      setState((prevState) => ({
        ...prevState,
        ...location
      }))
    } else {
      setState((prevState) => {
        delete prevState.latitude
        delete prevState.longitude
        return prevState
      })
    }
  }, [location, state.zip_code])

  return (
    <Box
      sx={{
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: 'border.main',
        p: 3,
        borderRadius: 2,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant='h5'>Primary program address</Typography>
        {isEditMode ? (
          <Box>
            <LoadingButton
              type='submit'
              loading={updateFacilityContactStatus === LOADING}
              size='small'
              disabled={loading}
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </LoadingButton>
            <IconButton
              sx={{ p: 0.375 }}
              onClick={handleCloseEditMode}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            sx={{ p: 0.375 }}
            onClick={handleOpenEditMode}
          >
            <EditIcon />
          </IconButton>
        )}
      </Box>

      <Grid container columnSpacing={1.5} rowSpacing={3}>
        <Grid item xs={12}>
          <PlaceAutoComplete
            fullWidth
            variant='outlined'
            control={control}
            name='address1'
            label='Street address'
            value={state.address1 || ''}
            onChange={handleAddressChange}
            error={!!errors.address1}
            helperText={errors.address1?.message}
            disabled={!isEditMode}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name='city'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                variant='outlined'
                label='City'
                onChange={handleInput}
                value={state.city || ''}
                error={!!errors.city}
                helperText={errors.city?.message}
                disabled={!isEditMode}
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name='state'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                select
                variant='outlined'
                label='State'
                onChange={handleInput}
                value={state.state || 0}
                fullWidth
                error={!!errors.state}
                helperText={errors.state?.message}
                disabled={!isEditMode}
                SelectProps={{
                  IconComponent: () => (
                    <Box
                      sx={{
                        minWidth: 24,
                        width: 24,
                        height: 24,
                        display: 'inline-flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        mr: 2,
                      }}
                    >
                      <ChevronDown width={12} height={6} />
                    </Box>
                  ),
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 220,
                        width: 250,
                      },
                    },
                  },
                }}
              >
                <MenuItem value={0} disabled>
                  Select a state
                </MenuItem>
                {statesList.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </TextField>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name='zip_code'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                variant='outlined'
                label='ZIP code'
                value={state.zip_code || ''}
                onChange={handleInput}
                error={!!errors.zip_code}
                helperText={errors.zip_code?.message}
                disabled={!isEditMode}
              />
            )}
          />
        </Grid>
        <Grid item sx={12} sm={6}>
          <PhoneNumberInput
            fullWidth
            variant='outlined'
            control={control}
            sx={{ mb: 3 }}
            name='phone_number'
            label='Phone Number'
            value={state.phone_number}
            onChange={handlePhoneNumberInput}
            error={!!errors.phone_number}
            helperText={errors.phone_number?.message}
            trigger={trigger}
            disabled={!isEditMode}
          />
        </Grid>
        <Grid item sx={12} sm={6}>
          <PhoneNumberInput
            fullWidth
            variant='outlined'
            sx={{ mb: 3 }}
            control={control}
            name='fax_number'
            label='Fax'
            value={state.fax_number}
            onChange={handleFaxNumberInput}
            error={!!errors.fax_number}
            helperText={errors.fax_number?.message}
            trigger={trigger}
            disabled={!isEditMode}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default Contact

