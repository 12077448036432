import React, { useEffect, useRef, useState } from 'react';
import {
  Modal,
  Paper,
  Typography,
  Box,
  IconButton,
  Button,
  TextField,
  MenuItem,
  Tooltip,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { ReactComponent as CloseIcon } from '../../assets/Icons/close.svg';
import { ReactComponent as LinkIcon } from '../../assets/Icons/link.svg';
import isEmail from 'validator/lib/isEmail';

import { useTeams } from '../../contexts/Teams';
import { useGeneralProfile } from '../../contexts/Organization';
import { LOADING, SUCCESS } from '../../constants/common';
import CheckBox from '../CheckBox';
import { useCommon } from '../../contexts/common';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { profileTeamInvitationSchema } from '../../validations/profileValidations';
import { trackClickEvent } from '../../mixpanel';


const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 580,
  p: 0,
  overflow: 'hidden',
};

const initialState = {
  emails: [],
  role: '',
  email: '',
  invite_as_mental_health: false,
};

function InvitationModal({ open, onClose }) {
  const {
    inviteMember,
    inviteMemberStatus,
    updateTeamsState,
    copyInvitationLink,
    copyInvitationLinkStatus,
    fetchTeams,
    teams,
  } = useTeams();
  const { facilityInfo } = useGeneralProfile();
  const { checkIfEmailExists } = useCommon();

  const [state, setState] = useState(initialState);
  const [isCopyMode, setIsCopyMode] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const ref = useRef(null);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    reset,
    trigger,
  } = useForm({
    resolver: zodResolver(profileTeamInvitationSchema),
    mode: 'onChange',
    defaultValues: {
      email: '',
      role: 0,
      invite_as_mental_health: false,
      is_email_exists: false,
      emails: []
    },
  });

  const formValues = useWatch({ control });


  useEffect(() => {
    fetchTeams();
  }, []);


  useEffect(() => {
    setState(prev => ({ ...prev, ...formValues }));
  }, [formValues]);

  useEffect(() => {
    if (inviteMemberStatus === SUCCESS) {
      updateTeamsState('inviteMemberStatus', '');
      fetchTeams();
      handleClose();
    }
  }, [inviteMemberStatus, fetchTeams, updateTeamsState]);

  useEffect(() => {
    if (copyInvitationLinkStatus === SUCCESS) {
      updateTeamsState('copyInvitationLinkStatus', '');
    }
  }, [copyInvitationLinkStatus, updateTeamsState]);

  const handleClose = () => {
    onClose();
    setState(initialState);
    setErrorMessage('');
    reset();
  };

  // const handleEmails = async (ev) => {
  //   const emails = ev.target.value.split(' ').filter(Boolean);
  //   const lastEmail = emails[emails.length - 1];

  //   if (!lastEmail?.length) return;

  //   const data = await checkIfEmailExists(lastEmail);
  //   if (data?.data?.code || state.emails.includes(lastEmail)) {
  //     setValue('is_email_exists', true);
  //     trigger('email');
  //     return;
  //   }
  //   if (errors?.email) {
  //     return;
  //   }
  //   setValue('emails', [...state.emails, lastEmail]);
  //   setValue('email', '');
  //   trigger('email');
  // };

  const handleEmailsInput = async (ev) => {
    if (ev.key === 'Enter' || ev.code === 'Enter' || ev.keyCode === 13) {
      ev.preventDefault();

      const email = state.email.trim();
      if (!isEmail(email)) {
        setErrorMessage('Please enter a valid email.');
        return;
      }

      if (state.emails.includes(email)) {
        setErrorMessage('This email has already been added.');
        return;
      }

      const data = await checkIfEmailExists(email);
      if (data?.data?.code) {
        setErrorMessage('This email is already registered in the database.');
        return;
      }

      setValue('emails', [...state.emails, email]);
      setValue('email', '');
      trigger('emails');
      setErrorMessage('');
    }
  };

  const removeEmail = (index) => {
    setValue('emails', state.emails.filter((_, i) => i !== index));
    trigger('email');
  };

  const handleInput = (ev) => {
    setValue(`${ev.target.name}`, ev.target.value);
    trackClickEvent(
      `Invited as ${ev.target.name}`,
      { button: 'Invite as', page: 'Team Invitation', component: 'Team Invitation Modal' }
    )
    trigger(ev.target.name);
  };

  const handleCheckbox = (ev) => {
    setValue(`${ev.target.value}`, ev.target.checked);
    trigger(ev.target.value);
  }

  const handleEmailsInputClick = () => {
    ref.current?.focus();
  };

  const onSubmit = () => {
    setIsCopyMode(false);
    if (state.emails.length === 0) {
      setErrorMessage('Please add at least one email.');
      return;
    }

    if (isValid) {
      inviteMember({
        team_id: teams?.id,
        user_id: teams?.user_id,
        emails: state.emails,
        role: state.role,
        invite_as_mental_health: state.invite_as_mental_health,
      });
      trackClickEvent(
        'Send Team Invitation Email',
        { button: 'Send Request', page: 'Team Invitation', component: 'Team Invitation Modal' }
      )
    }
  };

  const copyLink = () => {
    trigger();
    setIsCopyMode(true);
    if (isValid) {
      copyInvitationLink({
        team_id: facilityInfo?.team?.id || teams?.id,
        user_id: facilityInfo?.id || teams?.user_id,
        role: state.role,
        invite_as_mental_health: state.invite_as_mental_health,
      });
      trackClickEvent(
        'Copy Team Invittion Link',
        { button: 'Copy Invite Link', page: 'Team Invitation', component: 'Team Invitation Modal' }
      );
    }
  };

  return (
    <Modal open={open} onClose={handleClose} aria-labelledby='modal-modal-title' aria-describedby='modal-modal-description'>
      <Paper sx={modalStyle}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', pt: 3, px: 3, pb: 1.5 }}>
          <Typography variant='h4'>
            Send invitation to {facilityInfo?.info?.facility_name}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0.25, color: 'border.dark' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, px: 3, pb: 3 }}>
          <Typography variant='body2' sx={{ fontWeight: 600, color: 'text.primary' }}>
            To:
          </Typography>
          <Box
            sx={{
              border: '1px solid',
              borderColor: (errors?.email || errors?.emails) && !isCopyMode && (state.emails.length < 1 || state.email) ? 'error.main' : 'secondary.main',
              borderRadius: 1.5,
              minHeight: 127,
              display: 'flex',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              flexWrap: 'wrap',
              p: 2,
              gap: 1,
            }}
            onClick={handleEmailsInputClick}
          >
            {state.emails?.map((item, index) => (
              <Typography
                key={item}
                variant='body2'
                size='small'
                sx={{
                  backgroundColor: 'primary.light',
                  color: 'primary.main',
                  py: 0.5,
                  px: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 1,
                  borderRadius: 1.5,
                }}
              >
                {item}
                <IconButton sx={{ color: 'rgba(0 ,0, 0, 0.5)', p: 0 }} onClick={() => removeEmail(index)}>
                  <CloseIcon width={18} height={18} />
                </IconButton>
              </Typography>
            ))}
            <Controller
              name='email'
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  onKeyUp={handleEmailsInput}
                  onBlur={() => { setValue('email', ''); trigger('email'); setErrorMessage('') }}
                  fullWidth
                  variant='outlined'
                  onChange={(ev) => {
                    setValue('email', ev.target.value);
                  }}
                  value={state.email}
                  inputProps={{
                    ref,
                    sx: {
                      '&:not(textarea)': { py: 0.5, px: 0 },
                    },
                  }}
                  InputProps={{
                    sx: {
                      '&.Mui-focused fieldset, &:hover fieldset, fieldset': { border: '0!important' },
                    },
                  }}
                  error={!!errors.email}
                />
              )}
            />
          </Box>
          {errors.emails && errors.emails?.message && !errorMessage &&
            (
              <Typography color='error' fontSize='12px' variant='body1'>
                {errors?.emails?.message}
              </Typography>
            )
          }
          {errorMessage && (
            <Typography color='error' fontSize='12px' variant='body2'>
              {errorMessage}
            </Typography>
          )}
          <Typography variant='body2' sx={{ fontWeight: 600, color: 'text.primary' }}>
            Invite as:
          </Typography>
          <Controller
            name='role'
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                fullWidth
                select
                onChange={handleInput}
                error={!!errors?.role}
                helperText={errors?.role?.message}
                value={state.role}
                sx={{ mb: 2 }}
              >
                <MenuItem value='' disabled />
                <MenuItem value='team_admin'>Admin</MenuItem>
                <MenuItem value='team_assistant'>Assistant Admin</MenuItem>
                <MenuItem value='mental_health_provider'>Mental Health Provider</MenuItem>
              </TextField>
            )}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <CheckBox
              label='Assign an Individual Profile'
              value='invite_as_mental_health'
              onChange={handleCheckbox}
              checked={state.invite_as_mental_health}
            />
            <Tooltip
              arrow
              placement='top'
              title={
                <Typography textAlign='center' variant='body2'>
                  This allows the individual to be listed in search results, displaying their availability.
                  They will be clearly associated with your organization, showing your logo and organization
                  name next to their name.
                </Typography>
              }
            >
              <IconButton
                sx={{
                  color: 'warning.main',
                  border: '1px solid',
                  borderColor: 'warning.main',
                  backgroundColor: 'transparent',
                  p: 0.25,
                  minWidth: 21,
                  minHeight: 21,
                }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='10'
                  height='15'
                  viewBox='0 0 100 150'
                >
                  <rect
                    x='45'
                    y='20'
                    width='10'
                    height='80'
                    fill='currentColor'
                  />
                  <circle cx='50' cy='120' r='10' fill='currentColor' />
                </svg>
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              py: 2.5,
              mb: 2,
            }}
          >
            <Button
              startIcon={<LinkIcon />}
              sx={{
                p: 0,
                '&,&:hover': {
                  backgroundColor: 'transparent',
                },
              }}
              disableRipple
              onClick={copyLink}
              disabled={copyInvitationLinkStatus === LOADING}
            >
              Copy invite link
            </Button>
            <Typography variant='body2' color='text.secondary'>
              Link expire in 7 days
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <LoadingButton
              variant='contained'
              loading={inviteMemberStatus === LOADING}
              onClick={handleSubmit(onSubmit)}
            >
              Send Request
            </LoadingButton>
          </Box>
        </Box>
      </Paper>
    </Modal>
  );
}

export default InvitationModal;
