import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Avatar from '@mui/material/Avatar';

import BasicInfo from './components/BasicInfo';
import Contact from './components/Contact';

import { ReactComponent as UploadIcon } from '../../assets/Icons/upload.svg'
import { ReactComponent as BuildingIcon } from '../../assets/Icons/building.svg'
import { LOADING, SUCCESS } from '../../constants/common';
import { useGeneralProfile } from '../../contexts/Organization';
import AboutMe from '../../components/BasicProfile/AboutMe';
import { useAlert } from '../../contexts/Alert';
import { imageTypes } from '../../constants/lists';
import { fileSize, validateImageDimensions } from '../../utils/helper';

const ProfileOrganization = () => {
  const { facilityInfo, editFacilityPhoto, updatePhotoStatus, updateGeneralProfileState, removeFacilityProfilePhoto } = useGeneralProfile();
  const [profileImageURL, setProfileImageURL] = useState(null);
  const { sendAlert } = useAlert();


  useEffect(() => {
    if (facilityInfo.main_image) {
      handleFileRead(facilityInfo.main_image);
    }
  }, [facilityInfo.main_image]);

  const handleFileRead = (file) => {
    if (!file) {
      setProfileImageURL(null);
      return;
    }
    setProfileImageURL(file);
  };

  const handleImage = async (ev) => {
    if (ev.target.files && ev.target.files[0]) {
      const file = ev.target.files[0];
      if (fileSize(file.size)) {
        sendAlert(
          'File size exceeds 5MB. Please choose a smaller file.',
          'error'
        );
        return;
      }
      if (!imageTypes.includes(file.type)) {
        sendAlert(
          'Invalid file type. Only JPEG, JPG, PNG, and SVG images are allowed.',
          'error'
        );
        return;
      }
      const validateImageDimension = await validateImageDimensions(file);
      if (validateImageDimension !== true) {
        sendAlert(validateImageDimension, 'error');
        return;
      }
      const response = await editFacilityPhoto(file);
      if (response?.data?.data) {
        setProfileImageURL(response.data.data);
      }
    }
  };

  const handleDeleteImage = async () => {
    await removeFacilityProfilePhoto();
  }

  useEffect(() => {
    if (updatePhotoStatus === SUCCESS) {
      updateGeneralProfileState('updatePhotoStatus', '')
    }
  }, [updatePhotoStatus])

  return (
    <Paper
      sx={{
        p: 3,
        display: 'flex',
        flexDirection: 'column',
        gap: 3
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'border.dark'
          }}
        >
          <BuildingIcon width={24} height={24} />
        </Box>
        <Typography variant='h4' ml={1}>Organization Settings</Typography>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={8}>
          <BasicInfo />
        </Grid>
        <Grid item xs={4}>
          <Box
            sx={{
              borderWidth: '1px',
              borderStyle: 'solid',
              borderColor: 'border.main',
              p: 3,
              borderRadius: 2
            }}
          >
            <Typography variant='h5' mb={3}>Upload logo</Typography>
            {updatePhotoStatus === LOADING ? (
              <Box
                sx={{
                  border: '1px dashed',
                  borderColor: 'border.dark',
                  borderRadius: 1.5,
                  height: 145,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                  position: 'relative',
                  '&:hover': {
                    backgroundColor: 'primary.light'
                  }
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <Box>
                <Box
                  sx={{
                    border: '1px dashed',
                    borderColor: 'border.dark',
                    borderRadius: 1.5,
                    overflow: 'hidden',
                    height: 158,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'relative',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: profileImageURL ? 'transparent' : 'primary.light',
                    },
                  }}
                >
                  {profileImageURL ? (
                    <>
                      {/* Clickable Avatar */}
                      <label
                        style={{
                          width: '100%',
                          height: '100%',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                        }}
                      >
                        <Avatar
                          src={profileImageURL}
                          sx={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: 0,
                          }}
                        />
                        <Typography
                          variant="body2"
                          sx={{
                            textAlign: 'center',
                            position: 'absolute',
                            bottom: 0,
                            backgroundColor: 'rgba(0,0,0,0.4)',
                            width: '100%',
                            color: 'background.paper',
                          }}
                        >
                          Edit
                        </Typography>
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImage}
                          style={{ display: 'none' }}
                          name="main_image"
                        />
                      </label>
                      {/* Delete Button */}
                      <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleDeleteImage}
                        sx={{
                          position: 'absolute',
                          top: 5,
                          right: 5,
                          width: 22,
                          height: 22,
                          backgroundColor: 'background.paper',
                          borderRadius: '50%',
                          '&:hover': {
                            backgroundColor: 'error.light',
                            color: 'error.main',
                          },
                          zIndex: 10,
                        }}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    </>
                  ) : (
                    <label style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
                      <Button
                        startIcon={<UploadIcon />}
                        sx={{
                          p: 0,
                          '&:hover': {
                            backgroundColor: 'transparent',
                          },
                        }}
                        component="span"
                      >
                        Upload
                      </Button>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImage}
                        style={{ display: 'none', overflow: 'hidden' }}
                        name="main_image"
                      />
                    </label>
                  )}
                </Box>
              </Box>
            )}
            <Box
              component='ul'
              sx={{
                mx: 0,
                my: 3,
                pl: 2
              }}
            >
              <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Use a solid background color.</Typography>
              <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Use a graphical logo or image rather than text.</Typography>
              <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Leave some space around your icon.</Typography>
              <Typography variant='caption' color='text.secondary' component='li' lineHeight='150%'>Upload an image that is 265 px square or larger.</Typography>
            </Box>
            <Box
              component='label'
            >
              <Box
                component='input'
                sx={{
                  display: 'none'
                }}
                type='file'
                accept='image/*'
                onChange={handleImage}
              />
              <Button
                fullWidth
                sx={{
                  backgroundColor: 'primary.light'
                }}
                component='span'
              >
                Upload
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Contact />
      <AboutMe />
    </Paper>
  )
}

export default ProfileOrganization;